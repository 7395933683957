.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  padding: 80px 100px;
  text-align: left;
  clip-path: url("#clip");
}

.header {
  /* width: 100%; */
  color: #fff;
  background-color: #262d4d;
  height: 76px;
  padding: 10px 0;
  text-align: center;
  box-sizing: border-box;
}

fieldset {
  border: none;
  padding: 0;
}
