@font-face {
  font-family: "SharpGrotesk";
  font-weight: 300;
  src: url("./assets/SharpGroteskLight20.otf") format("opentype");
}
/* Comment in if needed */
@font-face {
  font-family: "SharpGrotesk";
  font-weight: 400;
  src: url("./assets/SharpGroteskBook20.otf") format("opentype");
}
@font-face {
  font-family: "SharpGrotesk";
  font-weight: 500;
  src: url("./assets/SharpGroteskMedium20.otf") format("opentype");
}
/* @font-face {
  font-family: "SharpGrotesk";
  font-weight: bold;
  src: url("./assets/SharpGroteskBold20.otf") format("opentype");
} */
/*@font-face {
  font-family: "SharpGrotesk";
  font-weight: normal;
  font-stretch: expanded;
  src: url("./assets/SharpGroteskMedium25.otf") format("opentype");
}*/
/* @font-face {
  font-family: "SharpGrotesk";
  font-weight: medium;
  font-stretch: expanded;
  src: url("./assets/SharpGroteskMedium25.otf") format("opentype");
} */

body {
  margin: 0;
  font-family: SharpGrotesk, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 300;
  background-color: #262d4d;
  font-size: 1em;
  color: #262d4d;
}

fieldset{
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: normal;
}

h1{
  text-transform: uppercase;
  font-size: 1.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  padding: 80px 100px;
  text-align: left;
  clip-path: url("#clip");
}

fieldset {
  border: none;
  padding: 0;
}
