@keyframes fadeSlideUpKeyFrames {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeSlideDownKeyFrames {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeSlideRightKeyFrames {
    from{
        transform: translateX(-100%);
        opacity: 0
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeSlideLeftKeyFrames {
  from{
      transform: translateX(100%);
      opacity: 0
  }
  to{
      transform: translateX(0);
      opacity: 1;
  }
}

